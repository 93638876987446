import React from 'react'
import style from './index.module.sass'
import {useQuery} from "../../services/query";
import {homeText} from "../../locale";


const Footer = () => {
    const query = useQuery()
    const lang = query.get('lang')
    const text = homeText(lang)

    return (
        <div className={style.box}>
            <div>
                &nbsp;
            </div>
            <div>
                <h2>{text.footer.title}</h2>
            </div>
            <div className={style.boxSocial}>
                <div>
                    <a href={text.footer.linkedin.link} target="_blank"
                       rel="noreferrer">{text.footer.linkedin.name}</a>
                </div>
                <div>
                    <a href={`mailto:${text.footer.email}`}>{text.footer.email}</a>
                </div>
                <div>
                    <a href={`tel:${text.footer.phone}`}>{text.footer.phone}</a>
                </div>
            </div>
        </div>
    )
}

export default Footer