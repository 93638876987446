import React from 'react'
import Hero from "./components/hero";
import History from "./components/history";
import {homeText} from "../../locale";

export const HomePath = '/'

const HomePage = () => {
    // This code is shit, needs replacing
    // const query = useQuery()
    // const history = useHistory()
    const lang = 'en'
    // const lang = query.get('lang')
    const text = homeText(lang)

    // useEffect(() => {
    //     let search = ''

    //     switch (lang) {
    //         case 'en':
    //             search = 'lang=en'
    //             break
    //         case 'ro':
    //             search = 'lang=ro'
    //             break
    //         default:
    //             search = 'lang=en'

    //     }
    //     // if (lang !== 'en' || lang !== 'ro') {
    //     //     history.push({
    //     //         pathname: '/',
    //     //         search: search
    //     //     })
    //     // }
    // }, [lang])


    return <div>
        <section>
            <Hero text={text} lang={lang}/>
        </section>
        <section>
            <History text={text} lang={lang}/>
        </section>
    </div>
}

export default HomePage