import TEXT_EN from "./text_en.txt";
import {renderData} from "../../../services/file";

export const project_22 = (ro = false) => {
    const data = {
        name: 'Video converter and compressor',
        video: '1010402345',
        images: [],
        src: 'https://github.com/calinvladth/video-manager',
        file_en: TEXT_EN,
        ro: ro
    }
    return renderData(data)
}