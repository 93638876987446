import {project_1} from "./projects/1_immersive/data";
import {project_2} from "./projects/2_hidropro/data";
import {project_3} from "./projects/3_raumgang/data";
import {project_4} from "./projects/4_aforc/data";
import {project_5} from "./projects/5_concept/data";
import {project_6} from "./projects/6_wallet/data";
import {project_7} from "./projects/7_vr_station/data";
import {project_8} from "./projects/8_theoscoding/data";
import {project_9} from "./projects/9_good_news/data";
import {project_10} from "./projects/10_beauty_shop/data";
import {project_11} from "./projects/11_coffee_shop/data";
import {project_12} from "./projects/12_shop_admin/data";
import {project_13} from "./projects/13_aimee/data";
import {project_14} from "./projects/14_odea/data";
import {project_15} from "./projects/15_delilah/data";
import {project_16} from "./projects/16_cecile_soaps/data";
import {project_17} from "./projects/17_smarttuningro/data";
import {project_18} from "./projects/18_service_renting/data";
import {project_19} from "./projects/19_refurbish_injectors/data";
import {project_20} from "./projects/20_map_organizer/data";
import {project_21} from "./projects/21_basic_shop/data";
import { project_22 } from "./projects/22_video_convertor_compressor/data";
import { project_23 } from "./projects/23_wifi_qr_code/data";


const AllProjects = (lang) => {
    const ro = lang === 'ro'
    return [
        {
            year: 2018,
            projects: [
                project_1(ro),
                project_2(ro),
                project_3(ro)
            ]
        },
        {
            year: 2019,
            projects: [
                project_4(ro),
                project_5(ro),
                project_6(ro)
            ]
        },
        {
            year: 2020,
            projects: [
                project_7(ro),
                project_8(ro),
                project_9(ro),
                project_10(ro)
            ]
        },
        {
            year: 2021,
            projects: [
                project_11(ro),
                project_12(ro),
                project_13(ro),
                project_14(ro),
                project_15(ro),
                project_16(ro),
            ]
        },
        {
            year: 2022,
            projects: [
                project_17(ro),
                project_18(ro),
            ]
        },
        {
            year: 2023,
            projects: [
                project_19(ro),
            ]
        },
        {
            year: 2024,
            projects: [
                project_20(ro),
                project_21(ro),
                project_22(ro),
                project_23(ro)
            ]
        }
    ]
}

export default AllProjects
