import TEXT_EN from "./text_en.txt";
import {renderData} from "../../../services/file";

export const project_21 = (ro = false) => {
    const data = {
        name: 'Basic shop with modern practices',
        video: '1009153081',
        images: [],
        src: 'https://github.com/calinvladth/shop-in-react',
        file_en: TEXT_EN,
        ro: ro
    }
    return renderData(data)
}