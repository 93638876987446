import React from 'react'
import style from './index.module.sass'
import {setBackgroundImage} from "../../../../services/image";
import Image from '../../../../assets/images/me.webp'
import RESUME_EN from '../../../../assets/pdf/resume.pdf'


const Hero = ({text, lang}) => (
    <div className={style.box}>
        <div className={style.boxTitle}>
            <h1>{text.hero.title}</h1>
        </div>
        <div className={style.boxTitle}>
            <h2>{text.hero.subtitle}</h2>
        </div>
        <div>
            <About text={text} lang={lang}/>
        </div>
    </div>
)

const About = ({text, lang}) => (
    <div className={style.about}>
        <div className={style.aboutImageContent}>
            <div className={style.aboutImage} style={setBackgroundImage(Image)}></div>
        </div>
        <div className={style.aboutSections}>
            <div className={style.aboutSection}>
                <p>{text.about.p1}</p>
                <a href={lang === 'ro' ? RESUME_EN : RESUME_EN} download>
                    <button>{text.about.button}</button> 
                </a>
            </div>
            <div className={style.aboutSection}>
                <p>{text.about.p2}</p>
            </div>
        </div>
    </div>
)

export default Hero